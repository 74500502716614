// tslint:disable:ordered-imports
import { Emitter } from "./support/Emitter";
import { remove } from "./support/domHelpers";

import { Tracker as Tracker2 } from "./trackingjs/colortracker-trackingjs";

import "./css/trackingjs";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export class Tracker extends Emitter {
  /*
    a) emits change event with bricks (with tracker frequency)
    b) provides get method for bricks
  */
  c: any;
  trackedBricks = [];
  cnt = 0;
  running = false;

  node: HTMLElement;
  buttonStartStop: HTMLButtonElement;
  status: HTMLElement;

  anim: number;

  constructor(parent, id, c) {
    super();

    const camId = c.camId || isMobile ? 1 : 0;

    new Tracker2({
      camId,
      videoHeight: 480 / 4,
      videoWidth: 640 / 4
    }, this.set.bind(this));

    //config
    this.c = {
      cols: 10,
      fps: 5
    };
    this.setConf(c);

    //data
    this.trackedBricks = [];

    this.cnt = 0;
    this.running = false;
    this.eventer = document.createTextNode(null);

    this._ui(parent, id);
  }

  setConf(conf): void {
    this.c = { ...this.c, ...conf };
  }

  getConf(): void {
    return this.c;
  }

  dbgSampleInit(): void {
    // tslint:disable:object-literal-sort-keys
    this.trackedBricks = [{
      x: 0.0, //
      y: 0.0,
      col: "red"
    }, {
      x: 0.5,
      y: 0.5,
      col: "red"
    }, {
      x: 1,
      y: 1,
      col: "yellow"
    }];
  }

  start(): void {

    if (this.running) {
      this.stop();
      this.buttonStartStop.innerText = "Start";
      return;
    }
    this.running = true;
    this.buttonStartStop.innerText = "Stop";
    //this.status.innerHTML = "started"
    const performAnimation = () => {
      if (!this.running) {
        this.stop();
        return;
      }
      setTimeout(function(this: Tracker): void {
        this.anim = requestAnimationFrame(performAnimation);
        this.status.innerText = this.cnt.toString();
        const bricks = [{
          x: ((this.cnt + Math.random()) % this.c.cols) / 10,
          y: Math.random() / 2,
          col: "red"
        }, {
          x: ((this.cnt + Math.random()) % this.c.cols + 1) / 10,
          y: Math.random() / 2,
          col: "red"
        }];
        this.trackedBricks = bricks;
        this.emit("change", bricks);
        this.cnt++;
      }.bind(this), 1000 / this.c.fps);
    };

    requestAnimationFrame(performAnimation);
  }

  stop(): void {
    this.running = false;
    cancelAnimationFrame(this.anim);
    this.anim = undefined;
  }

  get(): any[] {
    const that = this;
    return that.trackedBricks;
  }

  set(this: Tracker, trackedBricks): void {
    this.trackedBricks = trackedBricks.get();
  }

  _ui(parent, id): void {
    remove(document.getElementById(id));
    this.node = document.createElement("div");
    this.node.id = id;
    this.node.classList.add("area", "dbg");
    this.node.innerText = "Tracker";
    parent.appendChild(this.node);

    this.buttonStartStop = document.createElement("button");
    this.buttonStartStop.innerText = "Start";
    this.buttonStartStop.onclick = this.start.bind(this);
    this.node.appendChild(this.buttonStartStop);

    this.status = document.createElement("pre");
    this.node.appendChild(this.status);
  }
}
