// tslint:disable:ordered-imports
import { Emitter } from "./support/Emitter";
import { Measure } from "./Measure";
import { Ticker } from "./Ticker";
import { remove } from "./support/domHelpers";

/*
  brick         {x:1, y:2, col:"red"}
  pattern       [brick1, brick2]
  note          {x:1, y:2}
  tone          Note [] // polyphone!
  sound         {instrument: "bass", tone: Tone}
  track         {instrument: "bass": pattern: Pattern}
  loop          [ track ]
  bar           [ sound ] or {instrument: bass, Sound[], drums: Sound[]}
*/

//type Pattern = Brick[];
/*
interface Note {
  x: number;
  y: number;
}

interface Sound  {
  instrument: string;
  tone: Note[];
}

interface Bar {
  [index: string]: Note;
}
*/

function quant(val: number): number {
  //todo: maybe smarter quant, for bends, or more steps
  return Math.round(val);
}

export class Sequencer extends Emitter {
  c: any;
  node: HTMLElement;
  playNode: HTMLElement;
  patternsNode: HTMLElement;
  notesNode: HTMLElement;
  patterns: any;

  measure: Measure;

  ticker: Ticker;

  constructor(parent: HTMLElement, id, c: any) {
    super();

    this.ticker = new Ticker(c.patternLength);
    this.ticker.cb = function(this: Sequencer, step: number): void {
      this._updateNotes(this._notesForStep(step));
    }.bind(this);
    this._ui(parent, id);
  }

  setConf(conf: any): void {
    this.c = {...this.c, ...conf};
  }

  getConf(): any {
    return this.c;
  }

  addMeasure(): void {
    const measureNode = document.createElement("div");
    measureNode.setAttribute("id", "measure");
    this.node.appendChild(measureNode);

    this.measure = new Measure();
  }

  _ui(parent: HTMLElement, id: string): void {
    remove(document.getElementById(id));
    this.node = document.createElement("div");
    this.node.id = id;
    this.node.classList.add("area", "dbg");
    this.node.innerText = "Sq";
    parent.appendChild(this.node);

    const startButton = document.createElement("button");
    startButton.textContent = "start";
    startButton.onclick = this.startstop.bind(this);
    this.node.appendChild(startButton);

    this.playNode = document.createElement("pre");
    this.node.appendChild(this.playNode);
    this.patternsNode = document.createElement("pre");
    this.node.appendChild(this.patternsNode);
    this.notesNode = document.createElement("pre");
    this.node.appendChild(this.notesNode);
  }

  _updateNotes(txt: string): void {
    this.notesNode.innerHTML = txt;
  }

  _notesForStep(step: number): string {
    const bar = [];
    for (const i in this.patterns) { //[drums: [], bricks: []] ?
      //const bricks = i[Object.keys(i)[0]]; //fixme, wrong structure of patterns.better structure, no named keys
      for (const j of this.patterns[i]) {
        const noteAtStep = quant(j.x);
        if (noteAtStep === step) {
          bar.push(j);
        }
      }
    }
    if (this.measure) {
      this.measure.tick();
    }
    this.emit("change", bar);
    return JSON.stringify(bar);

  }

  updatePatterns(evt: CustomEvent): void {
    //this.patterns = evt.detail;
    this.patterns = evt.detail;
    this.patternsNode.innerText = JSON.stringify(evt.detail);
  }

  startstop(): void {
    this.ticker.play(); //metronome
    this.playNode.innerHTML = this.ticker.isPlaying ? "play" : "stop";
  }
}
