import "./css/measure.css";

export class Measure {
  constructor(){
    this.prev;
    this.enabled = true;
    this.deltas = [];

    this.stats = {
      min: 100000,
      max: -100000,
      avg: 0,
      cnt: 0
    };

    this.pattern = document.getElementById("measure");
    var onoff = document.createElement("input");
    onoff.setAttribute("type", "checkbox");
    onoff.setAttribute("checked", "true");
    this.pattern.appendChild(onoff);
    onoff.onclick = function(e) {
      this.enabled = e.currentTarget.checked;
    }.bind(this);
    
    var right = document.createElement("div");
    right.classList.add("txt");
    this.pattern.appendChild(right);
    var l = document.createElement("label");
    l.innerHTML = "Timing stats";
    right.appendChild(l);
    var nfo = document.createElement("div");
    nfo.innerHTML = "min / max / avg / steps";
    right.appendChild(nfo);
    
    

    this.statsN = document.createElement("div");
    right.appendChild(this.statsN);
    this.statsN.innerHTML = [this.stats.min, this.stats.max, Math.round(this.stats.avg), this.stats.cnt].join(" ");

    this.bars = document.createElement("div");
    this.pattern.appendChild(this.bars);
  }

  _addViz(delta) {
    let factor = 0.1;
    let b = document.createElement("span");
    b.classList.add("measure_bar");
    b.style.height = (Math.min(delta * factor, 300)) + "px";
    this.bars.appendChild(b);
    this.statsN.innerHTML = [this.stats.min, this.stats.max, Math.round(this.stats.avg), this.stats.cnt].join(" ");
  }

  tick() {
    if(!this.enabled) {
      return;
    }
    if(!this.prev){
      //first start
      this.prev = Date.now();
      return;
    }
    var now = Date.now();
    var delta = (now - this.prev);
    if(delta > 1000) {
      //pause detection
      this.prev = now;
      return;
    }
    this.stats.max = Math.max(this.stats.max, delta);
    this.stats.min = Math.min(this.stats.min, delta);
    this.stats.cnt++;
    this.stats.avg = (this.stats.avg*(this.stats.cnt-1) + delta) / this.stats.cnt;
    
    this.deltas.push(delta);
    this.prev = now;
    this._addViz(delta);
  }
}