import { remove } from "./support/domHelpers";

export class Ctrl {
  
  
  constructor(parent, id, modules){
    Object.assign(this, modules);

    //config
  

    //data
    this.config;

    // knowns
    
  
    //internals
    this._ui(parent, id);
  }
  
  _getConfigs(){
    return {
      ...{tra: this.tra.getConf()},
      ...{bri: this.bri.getConf()},
      ...{sto: this.sto.getConf()},
      ...{seq: this.seq.getConf()
      }};

  }

  _ui(parent, id) {
    remove(document.getElementById(id));
    this.node = document.createElement("div");
    this.node.id = id;
    this.node.classList.add("area", "dbg");
    this.node.id = "ctrl";
    this.node.innerText = "Ctrl";
    parent.appendChild(this.node);

    this.configNode = document.createElement("textarea");
    this.configNode.value = JSON.stringify(this._getConfigs(), undefined, 2);
    this.node.appendChild(this.configNode);

    this.applyConfButton = document.createElement("button");
    this.applyConfButton.innerText = "apply";
    this.applyConfButton.onclick = this.applyConf.bind(this, this.configNode);
    this.node.appendChild(this.applyConfButton);
  }


  applyConf(configNode) {
    const conf = JSON.parse(configNode.value);
    this.tra.setConf(conf.tra);
    this.bri.setConf(conf.bri);
    this.sto.setConf(conf.sto);
    this.seq.setConf(conf.seq);
    
    
    for( let i of this.pai) {
      i.setConf(conf.pai);
    }
    configNode.value = JSON.stringify(this._getConfigs(), undefined, 2);
    
    
    

  }
}