// tslint:disable:max-classes-per-file
import "./css/actors";
import { empty } from "./support/domHelpers";

export const TabactToggles = {
  play: "play",
  rec: "rec"
};

export class Choose {
  node: HTMLSelectElement;
  constructor(parent, options, selected?) {
    this.node = document.createElement("select");
    parent.appendChild(this.node);
    this.setOptions(options, selected);
  }

  setOptions(options, selected?): void {
    empty(this.node);
    for (const i in options) {
      const op = document.createElement("option");
      op.textContent = options[i];
      op.value = i;
      if (selected && options[i] === selected) {
        op.selected = true;
      }
      this.node.appendChild(op);
    }
  }
}

export class Toggle {
  node: HTMLInputElement;
  constructor(parent, type) {
    const rand = Math.random();
    this.node = document.createElement("input");
    this.node.setAttribute("type", "checkbox");
    this.node.classList.add("tgl");
    this.node.style[":after"] = "content: ASD";
    this.node.id = "cb_" + type + ":" + rand;
    parent.appendChild(this.node);

    const toggleLabel = document.createElement("label");
    toggleLabel.classList.add("btn", "tgl", type);
    toggleLabel.setAttribute("for", "cb_" + type + ":" + rand);
    parent.appendChild(toggleLabel);

  }
  setChecked(checked: boolean): void {
    this.node.checked = checked;
  }
}

export class Button {
  node: HTMLButtonElement;
  constructor(parent, type) {
    this.node = document.createElement("button");
    this.node.classList.add("btn", type);
    parent.appendChild(this.node);
  }
}
