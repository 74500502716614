// tslint:disable:object-literal-sort-keys
// tslint:disable:max-classes-per-file
import "./css/painter";
import { remove } from "./support/domHelpers";

export class Painter {
  brickRatio = 0.5;
  c: any;
  container: HTMLElement;
  grid: HTMLDivElement;
  bricks: HTMLElement;
  pattern: HTMLElement;

  constructor(board, id, name, config) {
    this.c = {
      brickSize: 52,
      patternLength: config.patternLength,
      quantized: true,
      rows: 10
    };
    remove(document.getElementById(id));
    const container = document.createElement("div");
    container.setAttribute("id", id);
    container.classList.add("pattern");

    board.appendChild(container);
    this.pattern = container;

    this.bricks = document.createElement("div");

    this._drawGrid(this.c.patternLength, this.c.rows);
    this.pattern.appendChild(this.bricks);
  }

  setConf(conf): void {
    this.c = { ...this.c, ...conf };
    this._setGridSize(this.c.patternLength, this.c.rows, this.c.brickSize);
  }

  getConf(): void {
    return this.c;
  }

  update(bricks): void {
    // update dom with new bricks (replace)
    this._reset();
    this._paint(bricks);
  }

  _drawGrid(cols, rows): void {
    const c = document.createElement("div");
    c.classList.add("gridcols");
    this.pattern.appendChild(c);
    for (let x = 0; x < cols; x++) {
      const g = document.createElement("div");
      g.classList.add("gridcol");
      g.style.width = `${100 / cols}%`;
      c.appendChild(g);
    }

    const c2 = document.createElement("div");
    c2.classList.add("gridrows");
    this.pattern.appendChild(c2);
    for (let y = 0; y <= this.c.rows; y++) {
      const g = document.createElement("div");
      g.classList.add("gridrow");
      g.style.height = `${100 / rows}%`;
      c2.appendChild(g);
     }
  }

  _setGridSize(cols, rows, size): void {
    const height = size * this.brickRatio;
    const grid = this.grid;
    const gridColor = "#666";
    // tslint:disable:max-line-length
    grid.style.backgroundImage =
      `repeating-linear-gradient(0deg,transparent,transparent ${height - 1}px,${gridColor} ${height - 1}px,${gridColor} ${height}px),
      repeating-linear-gradient(-90deg,transparent,transparent ${size - 1}px,${gridColor} ${size - 1}px,${gridColor} ${size}px)`;
    grid.style.backgroundSize = `${size}px ${height}px`;
    grid.style.width = `${cols * size + 1}px`;
    grid.style.height = `${(rows * size + 1) * this.brickRatio}px`;
  }

  _createBrickNode(brick): void {
    const brickNode = document.createElement("div");
    const overlayOffset = brick.col === "yellow" ? 1 : 0;
    // otherwise overlapping bricks of different colors are not detected
    // same colored bricks will be "merged" by tracker already (in most cases)
    brickNode.style.position = "absolute";
    brickNode.style.width = `${100 / this.c.patternLength}%`;
    brickNode.style.height = `${100 /  this.c.rows}%`;
    const x = this.c.quantized ? Math.round(brick.x) : brick.x;
    const y = this.c.quantized ? Math.round(brick.y) : brick.y;
    brickNode.style.bottom = (100 * y / this.c. rows + overlayOffset) + "%";
    brickNode.style.left = (100 * x / this.c.patternLength  + overlayOffset) + "%";
    brickNode.style["background-color"] = brick.col;
    this.bricks.appendChild(brickNode);
  }

  _paint(bricks): void {
    if (bricks) {
      for (const i of bricks) {
        this._createBrickNode(i);
      }
    }
    //console.log("paint");
  }

  _reset(): void {
    while (this.bricks.firstChild) {
      this.bricks.removeChild(this.bricks.firstChild);
    }
  }
}
