export class Emitter {
  eventer: Node;

  constructor() {
    this.eventer = document.createTextNode(null);
  }

  on(evt, callback): void {
    //just a mock for on syntax
    this.eventer.addEventListener(evt, callback);
  }
  emit(name, load): void {
    this.eventer.dispatchEvent(new CustomEvent(name, {detail: load}));
  }
}
