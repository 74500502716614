import { sounds } from "./audio/Mixer";
import { Emitter } from "./support/Emitter";

export class Store extends Emitter {
  c: any;
  bricksByBoard: any;
  recordingBoard: number;
  node: HTMLElement;
  status: HTMLElement;
  soundsByBoard: any; //boardId -> sound

  constructor(parent?: HTMLElement) {
    super();

    //data
    this.bricksByBoard = {};
    this.soundsByBoard = {};

    if (parent) {
      this._ui(parent);
      // dbg only: test own emitter for own UI
      super.on("change", (evt: CustomEvent) => this._uiUpdate(evt.detail));
    }
  }

  setConf(conf: any): void {
    this.c = {...this.c, ...conf};
    //this.setRecordingBoard(this.c.recordingInstrument, true); //fixme: needed?
  }

  getConf(): any {
    return this.c;
  }

  dbgSampleInit(): void {
    // tslint:disable:object-literal-sort-keys
    this.bricksByBoard = {
      0:  [{
        x: 0,
        y: 0,
        col: "red",
        sound: sounds.drums //fixme: store per board, not per brick. and/or even outside Store?
      }, {
        x: 2,
        y: 2,
        col: "red",
        sound: sounds.drums
      }, {
        x: 4,
        y: 0,
        col: "red",
        sound: sounds.drums
      }, {
        x: 6,
        y: 2,
        col: "red",
        sound: sounds.drums
      }],
      2:  [{
        x: 0,
        y: 0,
        col: "yellow",
        sound: sounds.keys
      }, {
        x: 1,
        y: 1,
        col: "yellow",
        sound: sounds.keys
      }, {
        x: 4,
        y: 5,
        col: "yellow",
        sound: sounds.keys
      }]
    };
    this.emit("change", this.bricksByBoard);
  }

  setRecordingBoard(id: number, checked: boolean): void {
    this.recordingBoard = checked ? id : undefined;
    this.emit("recChange", this.recordingBoard);
  }

  emptyBoard(id): void {
    this.bricksByBoard[id] = [];
    this.emit("change", this.bricksByBoard);
  }

  setSound(id, sound): void {
    this.soundsByBoard[id] = sound;
    if (this.bricksByBoard[id]) {
      this.bricksByBoard[id] = this._mixinSound(this.bricksByBoard[id], sound);
    }
  }

  _ui(parent: HTMLElement): void {
    this.node = document.createElement("div");
    this.node.classList.add("area", "dbg");
    this.node.innerText = "Multibricker";
    parent.appendChild(this.node);

    this.status = document.createElement("pre");
    this.node.appendChild(this.status);
  }

  _uiUpdate(bricks: any): void {
    this.status.innerText = JSON.stringify(bricks, undefined, 2);
  }

  _mixinSound(brickArray: any, sound?: sounds): void {
    for (const b of brickArray) {
      const newSound = sound || this.soundsByBoard[this.recordingBoard];
      b.sound = newSound;
    }
    return brickArray;
  }
  update(evt: CustomEvent): void {
    // update from tracker change event
    if (this.recordingBoard !== undefined) {
      this.bricksByBoard[this.recordingBoard] = this._mixinSound(evt.detail);
    }
    //fixme: emit only parts that changed (still full map, but only filled where changed)
    // only include output from recording insstrument? and for full change, still include all?
    // painter / sequencer would look for their id and early0out
    this.emit("change", this.bricksByBoard);
  }
}
