
import {Audiointerface} from "./Audiointerface";

interface BeepParams {
  freq: number;
  vol?: number;
  duration?: number;
  time?: number;

}

export class Beeper implements Audiointerface {
  id = "Beeper";
  ac: AudioContext;

  constructor() {

  }

  beep(p: BeepParams): void {
    if (!this.ac) {
      this.ac = new AudioContext();
    }
    console.log(p);
    const ac = this.ac;
    const v = ac.createOscillator();
    const u = ac.createGain();
    v.connect(u);
    v.frequency.value = p.freq;
    v.type = "square";
    u.connect(ac.destination);
    u.gain.value = p.vol * 0.01;
    v.start(ac.currentTime);
    v.stop(ac.currentTime + p.duration * 0.001);
  }

  _toneToBeep(tone): BeepParams {
    // tslint:disable:object-literal-sort-keys
    return {
      freq: tone.y * 100,
      vol: 100,
      duration: 100,
      time: 0
    };
  }
  play(bar): void {
    for (const tone of bar) {
      this.beep(this._toneToBeep(tone));
    }
  }
}
