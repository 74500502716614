// tslint:disable:ordered-imports
import { Audiointerface } from "./Audiointerface";
import { Audiofont } from "./Audiofont";
import { Beeper } from "./Beeper";
import { Midiout } from "./Midiout";
export enum deviceE {
  beeper,
  audiofont,
  midi,
  XXX
}

export type device = "beeper" | "audiofont" | "midi";

export enum sounds {
  drums = "🥁",
  guitar = "🎸",
  keys = "🎹"
  //bass = "bs"
}

export class Mixer {
  devices: Record<device, Audiointerface>;
  activeDevice: Audiointerface;

  constructor() {
    this.devices = {} as Record<device, Audiointerface>;
  }

  initDevice(id: device): Audiointerface {
    if (id in this.devices) {
      return this.devices[id];
    }
    switch (id) {
      case "beeper": {
        this.devices[id] = new Beeper();
        break;
      }
      case "midi": {
        this.devices[id] = new Midiout();
        break;
      }
      case "audiofont": {
        this.devices[id] = new Audiofont();
        break;
      }
      default: {
        break;
      }
    }
  }

  setOutputDevice(id: device): void {
    this.activeDevice =  this.devices[id];
  }

  play(bar): void {
    this.activeDevice.play(bar);
  }
}
