export function empty(node: HTMLElement): void {
  while (node && node.firstChild) {
    node.removeChild(node.firstChild);
  }
}

export function remove(node: HTMLElement): void {
  if (node) {
    node.parentElement.removeChild(node);
  }
}
