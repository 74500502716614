import { remove } from "./support/domHelpers";
import { Emitter } from "./support/Emitter";

export class Bricker extends Emitter {
  c: any;
  trackedBricks: any;
  processedBricks: any;

  node: HTMLElement;
  status: HTMLElement;

  loopFrame: number;

  constructor(parent, id, callBricksUpdate, c?) {
    /*
      a) if callBricksUpdate is undefined, need to connect it externally to tracker change event->update
      b) if callBricksUpdate method is set, calls for brick update from tracker in its own frezency
      parent undefined disables UI
    */
    super();
    /* config  */

    this.c = {
      fps: 5,
      invertX: false,
      invertY: false,
      patternLength: 10
    };
    this.setConf(c);

    // data
    this.trackedBricks = [];
    this.processedBricks = [];

    // init
    if (parent) {
      this._ui(parent, id);
      // dbg only: test own emitter for own UI
      this.on("change", (evt) => this._uiUpdate(evt.detail));
    }
    this._startInterval(callBricksUpdate);
  }

  setConf(conf): void {
    this.c = { ...this.c, ...conf };
  }

  getConf(): void {
    return this.c;
  }

  _ui(parent, id): void {
    remove(document.getElementById(id));
    this.node = document.createElement("div");
    this.node.id = id;
    this.node.classList.add("area", "dbg");
    this.node.innerText = "Bricker";
    parent.appendChild(this.node);

    this.status = document.createElement("pre");
    this.node.appendChild(this.status);
  }

  _uiUpdate(bricks): void {
    this.status.innerText = JSON.stringify(bricks);
  }

  _process(tracks): any {
    const bricks = [];
    for (const i of tracks) {
      let x = i.x;
      let y = i.y;
      if (this.c.invertX) {
        x = 1 - i.x;
      }
      if (this.c.invertY) {
        y = 1 - i.y;
      }
      x *= this.c.patternLength - 1;
      y *= this.c.patternLength - 1; // use same ratio for y.
      // no quantization! can be done easily in Draw / Seq, is just a round
      /*if(this.quantize) {
        i.x = Math.round(i.x);
        i.y = Math.round(i.y);
      }*/
      bricks.push({ x, y, col: i.col });
    }

    //todo: should be sorted for compare?

    return bricks;
  }

  _brickify(callBricksUpdate): void {
    if (callBricksUpdate) {
      this.trackedBricks = callBricksUpdate();

    }

    this.processedBricks = this._process(this.trackedBricks);
    //@todo: compare before emit
    const ev = new CustomEvent("change", { detail: this.processedBricks }); //must be named detail!
    this.eventer.dispatchEvent(ev);
    this.emit("change", this.processedBricks);
  }

  _startInterval(callBricksUpdate): void {
    //delayed updater
    const updateLoop = () => {
      setTimeout(function(this: Bricker): void {
        this._brickify(callBricksUpdate);
        this.loopFrame = requestAnimationFrame(updateLoop);
      }.bind(this , callBricksUpdate), 1000 / this.c.fps);
    };
    updateLoop();
  }

  update(evt): void {
    // update from tracker change event
    this.trackedBricks = evt.detail;
  }
}
