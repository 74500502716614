
declare var WebAudioFontPlayer: any;
type TWebAudioFontPlayer = any;
// declare function queueWaveTable(ac: AudioContext,
//   target: any, preset: any, when: number, pitch: number, duration: number, volume?: number, slides?: any): void;
declare var _tone_0250_SoundBlasterOld_sf2: any;
declare var _tone_0300_Aspirin_sf2_file: any;
declare var _drum_38_0_Chaos_sf2_file: any;

import { Audiointerface } from "./Audiointerface";
import { sounds } from "./Mixer";

interface PlayParams {
  pitch: number;
  preset?: any;
  duration: number;
}

export class Audiofont implements Audiointerface {
  id = "Audiofont";
  ac: AudioContext;
  player: TWebAudioFontPlayer;
  presets: any;

  constructor() {
    if (this.ac) {
      delete this.ac; //unclear if this helps hot reload
    }
    //this.init(); //cannot init here, need user click
  }

  static instruments(): any {
    return {
      drums: {

      }
    };
  }

  init(): void {
    if (this.ac) {
      return;
    }
    this.ac = new AudioContext();
    this.player = new WebAudioFontPlayer();
    //fixme: need to load dynamically
    this.player.loader.decodeAfterLoading(this.ac, "_tone_0250_SoundBlasterOld_sf2");
    this.player.loader.decodeAfterLoading(this.ac, "_tone_0300_Aspirin_sf2_file");
    this.player.loader.decodeAfterLoading(this.ac, "_drum_38_0_Chaos_sf2_file");

    this.presets = new Map<sounds, any>();
    this.presets.set(sounds.drums, _drum_38_0_Chaos_sf2_file);
    this.presets.set(sounds.guitar, _tone_0300_Aspirin_sf2_file);
    this.presets.set(sounds.keys, _tone_0250_SoundBlasterOld_sf2);
  }
  afPlay(p: PlayParams): boolean {
    this.init();
    this.player.queueWaveTable(this.ac, this.ac.destination
      , p.preset, 0, 12 * 1 + p.pitch, p.duration);
    return false;
  }

  _toneToAF(tone): PlayParams {
    // tslint:disable:object-literal-sort-keys
    console.log(Math.round(tone.y));
    return {
      pitch: 12 * 3 + Math.round(tone.y),
      duration: 250 / 1000,
      preset: this.presets.get(tone.sound)
    };
  }
  play(bar): void {
    this.init();
    for (const tone of bar) {
      this.afPlay(this._toneToAF(tone));
    }
  }
}
