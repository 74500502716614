import { sounds } from "../audio/Mixer";
import { Store } from "../Store";
import { RecBoard } from "./Board";

export class Mainboard {
  pattViews: Map<number, RecBoard>;
  sto: Store;
  constructor(sto: Store) {
    this.sto = sto;
    this.pattViews = new Map();

  }

  init(pattConfig, config): void {
    for (const p of pattConfig) {
      const instr = new RecBoard(p, config);
      instr.on("change", (evt) => {
        if (evt.detail.sound) {
          this.sto.setSound(evt.detail.sound.id, evt.detail.sound.sound);
        }
        if (evt.detail.empty) {
          this.sto.emptyBoard(evt.detail.empty.id);
        }
        if (evt.detail.rec) {
          this.sto.setRecordingBoard(evt.detail.rec.id, evt.detail.rec.checked);
        }
      });
      instr.addActors(p); //need to initUI late, otherwise emits in cnstr are not caught
      instr.setSoundOptions(sounds, p.sound);

      this.pattViews.set(p.id, instr);
    }

    this.sto.on("change", (evt) => this.updatePatterns(evt.detail));
    this.sto.on("recChange", (evt) => this.updateRec(evt.detail));
  }

  updatePatterns(newPatterns): void {
    for (const i of Object.keys(newPatterns)) { //fixme: bad mix of object kys and map key numbers
      this.pattViews.get(parseInt(i, 10)).painter.update(newPatterns[i]);
    }
  }

  updateRec(rec): void {
    for (const [, v] of this.pattViews) {
      v.setRec(false);
    }
    if (this.pattViews.has(rec)) {
      this.pattViews.get(rec).setRec(true);
    }
  }
}
