import { Mixer, sounds } from "./audio/Mixer";
import {Bricker} from "./Bricker";
import {Ctrl} from "./Ctrl";
import {Sequencer} from "./Sequencer";
import {Store} from "./Store";
import {Tracker} from "./Tracker";

import { SeqBoard } from "./boards/Board";
import { Mainboard } from "./boards/Mainboard";
import "./css/styles";

const p = new URLSearchParams(location.search);

function main(): void {
  /* parcel only, because hmr doesn't fully work */
  /*
  if (module.hot) {
    module.hot.accept(function () {
      window.location.reload();
    });
  }*/

/*
{
  "t": {
    "fps": 5,
    "cols": 10,
    "patternLength": 8,
    "brickSize": 26
  },
  "b": {
    "fps": 5,
    "invertX": false,
    "invertY": false,
    "patternLength": 8,
    "brickSize": 26
  },
  "store": {
    "recordingInstrument": "drums"
  },
  "p": {
    "brickSize": 26,
    "quantized": true,
    "patternLength": 8,
    "rows": 8
  }
}
*/

  document.getElementById("dbgbutton").onclick = () => {
    document.body.classList.toggle("showdbg");
  };

  interface Config {
    brickSize: number;
    invertX: boolean;
    patternLength: number;
    camId: number;
  }

  const config: Config = {
    brickSize: 26,
    camId: undefined,
    invertX: true,
    patternLength: 8
  };

  config.camId = p.has("camId") ? parseInt(p.get("camId"), 10) : 0;

  //setup / init
  const tra = new Tracker(window.document.body, "tra", config);
  tra.c.fps = 1; //dbg
  tra.dbgSampleInit();
  //tra.start();

  // a) tracker events trigger updates in bricker
  //const b = new Bricker(document.body);
  //t.on("change", (evt) => {b.update(evt)});

  // b) bricker calls for update in tracker
  // better because bricker doesn't get spammed with uncessary frequent updates
  //const b = new Bricker(document.body, t.get.bind(t));

  const bri = new Bricker(document.body, "bri", tra.get.bind(tra), config);

  const sto = new Store();
  //sto.setRecordingBoard(0);

  bri.on("change", (evt) => {sto.update(evt); });

  // tslint:disable:object-literal-sort-keys
  const instruments = [
    {
      id: 0,
      title: "🥁",
      sound: sounds.drums
    }, {
      id: 1,
      title: "🎸",
      sound: sounds.guitar
    }, {
      id: 2,
      title: "🎹",
      sound: sounds.keys
    }];

    const pattHub = new Mainboard(sto);
    pattHub.init(instruments, config);

  const seq = new Sequencer(document.body, "seq", config);
  sto.on("change", (evt) => {seq.updatePatterns(evt); });
  //seq.startstop(); // cannot start. AudioContext needs user click
  seq.addMeasure();

  new SeqBoard({id: 100, title: "Sq", sound: null}, config, seq);
  //pai3.tabar.addToggle("play").onclick = seq.startstop.bind(seq);

  sto.dbgSampleInit();

  const mix = new Mixer();
  mix.initDevice("beeper");
  mix.initDevice("midi");
  mix.initDevice("audiofont");

  mix.setOutputDevice("audiofont");
  seq.on("change", (evt) => {mix.play(evt.detail); });

  new Ctrl(document.body, "ctrl",  {tra, bri, seq, sto/*, pai: [pai1, pai2]*/});
}

main();
