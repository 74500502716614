export class Ticker {
  audioContext: AudioContext;
  nextClockTime: number = 0.0;
  startTime: number = 0.0;
  scheduleAheadTime: number = 0.1;
  timerID: number;
  bpm = 128;
  tempo: number;

  beatCounter = 0;
  beatDiv = 8;
  stepNum = 0;

  playPressed = false;
  isPlaying = false;
  cb: any;

  constructor(beatDiv = 8) {
    this.beatDiv = beatDiv;
  }

  //schedules when the next clock should fire
  _scheduleClock(): void {
    let currentTime = this.audioContext.currentTime;
    currentTime -= this.startTime;

    while (this.nextClockTime < currentTime + this.scheduleAheadTime) {
      if (this.playPressed) {
        setTimeout( () => {
          //send midi clock start only the first beat!
          //timeout needed to avoid quick first pulse
          this.playPressed = false;
          //midiDevice.send([0xFA]);
          //midiDevice.send([0xF8]);
        }, currentTime + this.nextClockTime);
      }
      this._advanceClock();
    }
    this.timerID = window.setTimeout(this._scheduleClock.bind(this), 0);
  }

  //move the clock forward by tempo intervals (24ppq)
  _advanceClock(): void {
    //send midi clock signal
    //midiDevice.send([0xF8]);
    //advance beat
    this.beatCounter++;
    if (this.beatCounter >= 192) {
      this.beatCounter = 0;
    }

    //calculate divisions per step
    if (this.beatCounter % this.beatDiv === 0) {
      this.stepNum++;
      if (this.stepNum >= 8) {
        this.stepNum = 0;
      }
      if (this.cb) {
        this.cb(this.stepNum);
      }
      //beep(10, 240, 100);
      //midiOut.playNote(song[stepNum], 1, { duration: 50 })
    }
    //the next clock will be at the next tempo marker
    this.nextClockTime += (60 / this.bpm / 24); // = tempo;
  }

  //Stops the MIDI clock
  stop(): void {
    //midiDevice.send([0xFC]);
    this.isPlaying = false;
    window.clearTimeout(this.timerID);
  }

  play(): void {
    if (this.isPlaying) {
      this.stop();
      return;
    }
    this.playPressed = true;
    this.isPlaying = true;
    this.nextClockTime = 0;
    this.tempo = 60 / this.bpm / 24;
    if (!this.audioContext) {
      //reuse
      this.audioContext = new AudioContext();
    }
    this.startTime = this.audioContext.currentTime + 0.005;
    //beep(10, 240, 100);
    this._scheduleClock();
  }

  //@@au
  setBpm(bpm_: number): void {
    this.bpm = bpm_;
    this.tempo = 60 / this.bpm / 24;
  }
}
