
declare var WebMidi: any;
//import "../../lib/webmidi.min.js";

import {Audiointerface} from "./Audiointerface";

const n = ["C", "D", "E", "F", "G", "A", "H", "C"];

interface MidiParams {
  note: string;
  channel?: number;
  duration?: number;
}

export class Midiout implements Audiointerface {
  id = "Midiout";
  ac: AudioContext;
  midiout: any;

  constructor() {
    WebMidi.enable(() => {
      console.log(WebMidi.inputs);
      console.log(WebMidi.outputs);
      this.midiout = WebMidi.getOutputByName("2- JD-Xi");
    });
  }

  playNote(p: MidiParams): void {
    console.log(p.note);
    this.midiout.playNote(p.note, p.channel, { duration: p.duration });
    //this.midiout.playNote(p.note);
  }

  _toneToBeep(tone): MidiParams {
    // tslint:disable:object-literal-sort-keys
    console.log(Math.round(tone.y));
    return {
      note: n[Math.round(tone.y)] + "3",
      channel: undefined,
      duration: 150
    };
  }
  play(bar): void {
    for (const tone of bar) {
      this.playNote(this._toneToBeep(tone));
    }
  }
}
