import { Button, Choose, Toggle } from "../Actors";
import { sounds } from "../audio/Mixer";
import { Painter } from "../Painter";
import { Sequencer } from "../Sequencer";
import { remove } from "../support/domHelpers";
import { Emitter } from "../support/Emitter";

export interface BoardOptions {
  id: number;
  title: string;
  sound: sounds;
}

abstract class Board extends Emitter {
  painter: Painter;
  container: HTMLElement;
  actorContainer: HTMLElement;
  actors: Map<string, any> = new Map();
  constructor(i: BoardOptions, config: any) {
    super();
    const parent = document.body;
    remove(document.getElementById("board_" + i.id));
    this.container = document.createElement("div");
    this.container.setAttribute("id", "board_" + i.id);
    this.container.classList.add("board");
    parent.appendChild(this.container);

    this.actorContainer = document.createElement("div");
    this.actorContainer.classList.add("actors");
    this.container.appendChild(this.actorContainer);

    //this.tabar = new Tabar(this.container, i.title);
    this.painter = new Painter(this.container, "painter_" + i.id, i.title, config);
  }
}

// tslint:disable-next-line:max-classes-per-file
export class RecBoard extends Board {
  id: number;
  actions2: HTMLDivElement;
  soundOptions: HTMLSelectElement;

  constructor(i, config: any) {
    super(i, config);
    this.id = i.id;
  }

  addActors(i): void {
    this.soundChanged(i.sound); // fixme: a bit weird here, but cannot fire in cnstr (no listeners yet)

    const soundChooser = new Choose(this.actorContainer, sounds, i.sound);
    soundChooser.node.onchange = (evt) => {
      this.soundChanged((evt.currentTarget as HTMLSelectElement).selectedOptions[0].textContent as sounds);
    };
    this.actors.set("sounds", soundChooser);

    const rec = new Toggle(this.actorContainer, "rec");
    rec.node.onclick = (evt) => {
      const checkbox: HTMLInputElement = evt.currentTarget as HTMLInputElement;
      this.emit("change", {rec: {id: this.id, checked: checkbox.checked}});
    };
    this.actors.set("rec", rec);

    const erase = new Button(this.actorContainer, "erase");
    erase.node.onclick = (evt) => {
      this.emit("change", {empty: {id: this.id}});
    };
    this.actors.set("erase", erase);
  }

  setSoundOptions(options, selected?): void {
    this.actors.get("sounds").setOptions(options, selected);

  }

  soundChanged(sound: sounds): void {
    this.emit("change", {sound: {id: this.id, sound}});
  }

  setRec(checked: boolean): void {
    this.actors.get("rec").setChecked(checked);
  }
}

// tslint:disable-next-line:max-classes-per-file
export class SeqBoard extends Board {
  constructor(i: BoardOptions, config: any, seq: Sequencer) {
    super(i, config);
    seq.on("change", (evt) => {this.painter.update(evt.detail); });
    const play = new Toggle(this.actorContainer, "play");
    play.node.onclick = seq.startstop.bind(seq);
    this.actors.set("play", play);

  }
}
